import React from "react"
import "./projects.scss"
import SVG from "../svg"
import { LazyLoadImage } from "react-lazy-load-image-component"
import SessionsManagement from "../../assets/images/sessionsmanagement.png"
import Assessifai from "../../assets/images/assessifai.png"
import YoutubeFeed from "../../assets/images/youtubefeed.png"
import Wordle from "../../assets/images/wordle.png"

const projects = [
  {
    title: "AssessifAI",
    photo: Assessifai,
    description:
      "Your AI-powered assessment generation tool to test your understanding of a given knowledge base.",
    technologies: ["react", "express", "openai"],
    link: "https://assessifai.netlify.app",
  },
  {
    title: "YoutubeFeed",
    photo: YoutubeFeed,
    description:
      "An application powered by Whisper and OpenAI that delivers daily summaries of new videos from your favorite subscribed channels.",
    technologies: ["react", "express", "openai", "mongo", "docker"],
    link: "https://youtube-feed.netlify.app",
  },
  {
    title: "Sessions Management",
    photo: SessionsManagement,
    description:
      "A platform about organizing and managing client sessions. Try it out with admin/admin.",
    technologies: ["graphql", "react", "mongo", "node", "redux"],
    link: "https://sessions-management.netlify.app",
  },
  {
    title: "Wordle",
    photo: Wordle,
    description: "Clone of the popular game Wordle",
    technologies: ["html", "javascript", "css"],
    link: "https://lamouchefatale-wordle.netlify.app/",
  },
]

function Projects() {
  return (
    <section className="grey projects" id="projects">
      <div className="projects-title">
        <h2>PROJECTS</h2>
        <h3>Some of my projects</h3>
      </div>
      {projects.map(({ title, description, technologies, link, photo }) => (
        <article className="project-card" key={title}>
          <div className="project-card-inner">
            <div className="project-card-front">
              <h3 className="project-title">{title}</h3>
              <p className="project-description">{description}</p>
              <ul className="project-technologies">
                {technologies.map((technology) => (
                  <li key={technology}>
                    <SVG className="project-technology" name={technology} />
                  </li>
                ))}
              </ul>
            </div>
            <div className="project-card-back">
              <div className="project-overlay" />
              <LazyLoadImage
                className="project-screenshot"
                alt={`${title} screenshot`}
                src={photo}
              />
              <a
                target="_blank"
                href={link}
                className="project-link"
                rel="noreferrer"
              >
                View Project
              </a>
            </div>
          </div>
        </article>
      ))}
    </section>
  )
}

export default Projects
