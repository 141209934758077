import * as React from "react"

function Code(props) {
  return (
    <svg
      height="1em"
      viewBox="0 0 512 512"
      width="1em"
      fill="currentColor"
      {...props}
    >
      <path d="M241.5 1.5c-8.3 1.6-16 4.2-23.4 8-8.8 4.4-154 88.3-160 92.5-15.4 10.4-27.2 27.6-32.3 47l-2.3 8.5v197l2.3 8.5c5.1 19.4 16.9 36.6 32.3 47 6 4.2 151.2 88.1 160 92.5 24.2 12.3 51.6 12.3 75.8 0 8.8-4.4 154-88.3 160-92.5 15.4-10.4 27.2-27.6 32.3-47l2.3-8.5v-197l-2.3-8.5c-5.1-19.4-16.9-36.6-32.3-47-6.5-4.5-151.8-88.4-160.5-92.8-16.6-8.2-34.7-10.9-51.9-7.7m29 41.2c5.7 2.2 154.6 87.8 161.7 93 5.6 4.1 10.8 10.9 14 18.3l2.3 5.5.3 93.5c.2 61.5-.1 95.1-.8 98.3-.5 2.7-2.2 7.3-3.6 10.4-5.4 11.3-6.6 12.1-91.5 61-43.1 24.9-80.4 46-82.9 46.9-6.6 2.6-21.9 2.4-28.5-.2-6-2.4-154.9-88.2-161.7-93.1-5.5-4-10.5-10.5-13.9-18.3l-2.4-5.5-.3-93.5c-.2-59.5.1-95.2.7-98.2 1.7-7.6 6.2-15.8 11.6-21.2 4.1-4.1 20.9-14.2 83.6-50.4 43.2-24.8 80.5-45.9 83-46.6 2.4-.8 5.3-1.7 6.4-2 4-1 17.1.2 22 2.1"></path>
      <path d="M143.5 174.1c-11.2 4.2-16.5 16.7-11.6 27.5 1.5 3.3 7.8 9.2 31.4 29 16.3 13.7 29.6 25.1 29.6 25.4s-13.3 11.7-29.6 25.4c-25 21.1-29.8 25.5-31.5 29.4-4.2 9.4-.3 20.9 8.7 25.6 4.9 2.5 13.6 2.7 18 .4 1.7-.9 20.6-16.5 42.2-34.8 42.1-35.6 43.3-36.9 43.3-46s-1.2-10.4-43.3-46c-21.6-18.3-40.5-33.8-42-34.6-3.8-2-11.5-2.6-15.2-1.3M264.5 300.4c-9 4-14 14.3-11.5 23.7 1.5 5.6 8 12.3 13.5 13.8 2.7.7 19.8 1.1 51.5 1.1h47.5l5.2-2.4c15-7 15-28.9-.2-35.7-3.7-1.7-7.6-1.9-53.5-1.8-39 0-50.1.3-52.5 1.3"></path>
    </svg>
  )
}

const MemoCode = React.memo(Code)
export default MemoCode
